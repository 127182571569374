import { Component, OnInit } from '@angular/core';
import {HeaderMenuBuilder} from './builder/header-menu-builder';
import {HeaderMenu} from './config/header-menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  headerMenuConfig: HeaderMenu;
  showMobileMenu = false;

  constructor(
      protected headerMenuBuilder: HeaderMenuBuilder,
  ) {}

  ngOnInit(): void {

    this.buildMenuItems();
  }

  protected buildMenuItems() {

    this.headerMenuBuilder
        .clear()
        .addIcon('./assets/icons/SevData_Signet_black.png', './assets/icons/SevData_Signet_farbe.png', 'SevData')
        .addItem('menu.product', '/xchangehub')
        .addItem('menu.interfaces', '/integrations')
        .addItem('menu.pricing', '/pricing')
        .addItem('menu.aboutus', '/about')
        .addItem('menu.support', 'https://sevdata.atlassian.net/servicedesk/customer/portal/3', true)

    this.headerMenuConfig = this.headerMenuBuilder.buildConfig();
  }
}
