<header class="bg-white">
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div class="flex items-center gap-x-12">
            <a [routerLink]="['/']" class="-m-1.5 p-1.5 cursor-pointer group">
                <span class="sr-only">Sevdata GmbH</span>
                <img class="h-8 w-auto inline-block group-hover:hidden" [src]="headerMenuConfig.icon.path" [alt]="headerMenuConfig.icon.alt">
                <img class="h-8 w-auto hidden group-hover:inline-block" [src]="headerMenuConfig.icon.alternate" [alt]="headerMenuConfig.icon.alt">
                <span class="text-sm font-semibold leading-6 text-gray-900 ml-4 group-hover:text-teal-500">Start</span>
            </a>
            <div class="hidden lg:flex lg:gap-x-12">
                <ng-container *ngFor="let item of headerMenuConfig.items">
                    <a *ngIf="!item.isExternal" class="text-sm font-semibold leading-6 text-gray-900 hover:text-teal-500" [routerLink]="[item.targetUrl]" [translate]="item.label"></a>
                    <a target="_blank" *ngIf="item.isExternal" class="text-sm font-semibold leading-6 text-gray-900 hover:text-teal-500" [href]="item.targetUrl" [translate]="item.label"></a>
                </ng-container>
            </div>
        </div>
        <div class="flex lg:hidden">
            <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:text-teal-500" (click)="showMobileMenu = true">
                <span class="sr-only">Open main menu</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </button>
        </div>
        <div class="hidden lg:flex">
            <app-language-switcher></app-language-switcher>
            <a href="https://app.sevdata.io/" target="_blank" class="text-sm font-semibold leading-6 text-gray-900 ml-8"><span translate="menu.login" class="mr-1.5"></span> <span aria-hidden="true">&rarr;</span></a>
        </div>
    </nav>
    <!-- Mobile menu, show/hide based on menu open state. -->
    <div class="lg:hidden" role="dialog" aria-modal="true" *ngIf="showMobileMenu">
        <!-- Background backdrop, show/hide based on slide-over state. -->
        <div class="fixed inset-0 z-10"></div>
        <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div class="flex items-center justify-between">
                <a [routerLink]="['/']" class="-m-1.5 p-1.5">
                    <span class="sr-only">SevData GmbH</span>
                    <img class="h-8 w-auto" [src]="headerMenuConfig.icon.path" [alt]="headerMenuConfig.icon.alt">
                </a>
                <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700 hover:text-teal-500" (click)="showMobileMenu = false">
                    <span class="sr-only">Close menu</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div class="mt-6 flow-root">
                <div class="-my-6 divide-y divide-gray-500/10">
                    <div class="space-y-2 py-6">
                        <ng-container *ngFor="let item of headerMenuConfig.items">
                            <a *ngIf="!item.isExternal" class="-mx-3 block rounded-lg px-3 py-2 hover:text-teal-500 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" [routerLink]="[item.targetUrl]" [translate]="item.label"></a>
                            <a target="_blank" *ngIf="item.isExternal" class="-mx-3 block rounded-lg px-3 hover:text-teal-500 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" [href]="item.targetUrl" [translate]="item.label"></a>
                        </ng-container>
                        <div class="pt-4">
                            <app-language-switcher></app-language-switcher>
                        </div>
                    </div>
                    <div class="py-6">
                        <a href="https://app.sevdata.io/" target="_blank" class="-mx-3 block rounded-lg px-3 hover:text-teal-500 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" translate="menu.login"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<!--
<div class="relative bg-white">
    <div class="mx-auto flex max-w-screen-xl items-center justify-between p-6 md:justify-start md:space-x-10 lg:px-8">
        <div class="flex justify-start lg:w-0 lg:flex-1">
            <a [routerLink]="['/']" class="flex">
                <img class="h-8 w-auto mr-3 sm:h-10 inline-block" [src]="headerMenuConfig.icon.path" [alt]="headerMenuConfig.icon.alt">
            </a>

            <a [routerLink]="['/about']" class="text-base font-medium text-gray-500 hover:text-gray-900 ml-6 mt-2" translate="menu.aboutus"></a>
        </div>


        <nav class="hidden space-x-10 md:flex"></nav>
        <div class="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3" target="_blank" class="text-base font-medium text-gray-500 hover:text-gray-900 mr-6" translate="menu.support"></a>
            <a href="https://app.sevdata.io/" target="_blank" class="text-base font-medium text-gray-500 hover:text-gray-900 mr-6" translate="menu.login"></a>

            <app-language-switcher></app-language-switcher>
        </div>
    </div>


    <div class="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden">
        <div class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="px-5 pt-5 pb-6">
                <div class="flex items-center justify-between">
                    <div class="flex">
                        <img [routerLink]="['/']" class="h-8 w-auto mr-3" [src]="headerMenuConfig.icon.path" [alt]="headerMenuConfig.icon.alt">
                    </div>

                </div>
            </div>
            <div class="py-6 px-5">
                <div class="grid grid-cols-2 gap-4">
                    <a [routerLink]="['/about']" class="inline-block col-span-2" translate="menu.aboutus"></a>
                    <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3" target="_blank" class="text-base font-medium text-gray-900 hover:text-gray-700" translate="menu.support"></a>
                    <a href="https://app.sevdata.io/" target="_blank" class="text-base font-medium text-gray-900 hover:text-gray-700" translate="menu.login"></a>
                </div>
            </div>
        </div>
    </div>
</div>
-->