<div class="relative isolate px-6 pt-14 lg:px-8">
  <div class="absolute inset-x-0 -z-10 overflow-hidden w-full h-full">
    <app-lines [amountOfLines]="32"></app-lines>
  </div>
  <div class="mx-auto max-w-3xl py-32 sm:py-48 lg:py-56">
    <div class="text-center">
      <h1 class="text-5xl font-bold tracking-tight text-gray-900 sm:text-5xl" data-aos="fade-up" data-aos-duration="1000" [innerHTML]="'landing.hero.subtitle' | translate"></h1>
      <p class="mt-6 text-lg leading-8 text-gray-600" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="landing.hero.title"></p>
      <p class="mt-4 italic text-lg leading-8 text-gray-600" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="landing.hero.claim"></p>
      <div class="mt-10 flex items-center cursor-pointer flex justify-center gap-x-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
        <div class="inline-block mx-auto relative">
          <div (click)="scrollToElement(target)" class="text-sm font-semibold leading-6 text-gray-900 scroll" style="padding-left: 28px" translate="landing.hero.button"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-white py-24 sm:py-32 pt-0 sm:pt-0" #target>
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
      <p class="text-base font-semibold leading-7 text-teal-600" translate="landing.features.title"></p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="landing.features.subtitle"></h1>
      <div class="mx-auto mt-8 max-w-2xl sm:mt-10 lg:mt-12 lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div class="flex flex-col">
            <dt class="text-base font-semibold leading-7 text-gray-900" translate="landing.features.interchange.heading"></dt>
            <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto" translate="landing.features.interchange.text"></p>
              <p class="mt-6">
                <a href="/xchangehub" class="text-sm font-semibold leading-6 text-teal-600"><span class="mr-2" translate="landing.features.interchange.button"></span> <span aria-hidden="true">→</span></a>
              </p>
            </dd>
          </div>
          <div class="flex flex-col">
            <dt class="text-base font-semibold leading-7 text-gray-900" translate="landing.features.consulting.heading"></dt>
            <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto" translate="landing.features.consulting.text"></p>
              <p class="mt-6">
                <a href="https://calendly.com/christoph-sevdata" target="_blank" class="text-sm font-semibold leading-6 text-teal-600"><span class="mr-2" translate="landing.features.consulting.button"></span> <span aria-hidden="true">→</span></a>
              </p>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
  <div class="relative overflow-hidden pt-16 lg:pt-20">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <img class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" src="./assets/images/app-mockup-02.png" alt="">
      <div class="relative" aria-hidden="true">
        <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
      </div>
    </div>
  </div>
</div>


<div class="bg-white pb-12 sm:pb-16 pt-0 sm:pt-0">
  <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
    <p class="text-base font-semibold leading-7 text-teal-600" translate="landing.app.hero.title"></p>
    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="landing.app.hero.subtitle"></h1>


    <div class="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
      <div class="relative lg:col-span-3">
        <div class="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
          <img class="h-80 object-cover object-left" src="./assets/images/bento-mockup-01.png" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-teal-600" translate="landing.app.fulfillment.title"></h3>
            <p class="mt-2 max-w-lg text-sm/6 text-gray-600" translate="landing.app.fulfillment.short"></p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]"></div>
      </div>
      <div class="relative lg:col-span-3">
        <div class="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
          <img class="h-80 object-cover object-left lg:object-right" src="./assets/images/bento-mockup-02.png" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-teal-600" translate="landing.app.ecommerce.title"></h3>
            <p class="mt-2 max-w-lg text-sm/6 text-gray-600" translate="landing.app.ecommerce.short"></p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]"></div>
      </div>

      <div class="relative lg:col-span-2">
        <div class="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-bl-[calc(2rem+1px)]">
          <img class="h-80 object-cover object-left" src="https://tailwindui.com/plus/img/component-images/bento-01-speed.png" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-indigo-600">Speed</h3>
            <p class="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Built for power users</p>
            <p class="mt-2 max-w-lg text-sm/6 text-gray-600">Sed congue eros non finibus molestie. Vestibulum euismod augue.</p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]"></div>
      </div>
      <div class="relative lg:col-span-2 hover:cursor-pointer group" [routerLink]="['/integrations']" (click)="scrollToTop()">
        <div class="absolute inset-px rounded-lg bg-white group-hover:shadow-md"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
          <img class="h-80 object-cover object-center" src="./assets/images/bento-01-integrations.png" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-teal-600 group-hover:font-bold" translate="menu.interfaces"></h3>
            <p class="mt-2 max-w-lg text-sm/6 text-gray-600" translate="integrations.text"></p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
      </div>
      <div class="relative lg:col-span-2 hover:cursor-pointer group" [routerLink]="['/about']" (click)="scrollToTop()">
        <div class="absolute inset-px rounded-lg bg-white group-hover:shadow-md max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]"></div>
        <div class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
          <img class="h-80 object-cover object-center" src="./assets/images/bento-01-network.png" alt="">
          <div class="p-10 pt-4">
            <h3 class="text-sm/4 font-semibold text-teal-600 group-hover:font-bold" translate="menu.aboutus"></h3>
            <p class="mt-2 max-w-lg text-sm/6 text-gray-600" translate="about-us.title.headline"></p>
          </div>
        </div>
        <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]"></div>
      </div>
    </div>
  </div>
</div>

<!-- This example requires Tailwind CSS v3.0+ -->
<div class="bg-gray-800 py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-2xl sm:text-center">
      <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl" translate="landing.pricing.heading"></h2>
      <p class="mt-6 text-lg leading-8 text-white" translate="landing.pricing.text"></p>
    </div>
    <div class="bg-white mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
      <div class="p-8 sm:p-10 lg:flex-auto">
        <h3 class="text-2xl font-bold tracking-tight text-gray-900" translate="landing.pricing.quota"></h3>
        <p class="mt-6 text-base leading-7 text-gray-600" translate="landing.pricing.info"></p>
        <div class="mt-10 flex items-center gap-x-4">
          <h4 class="flex-none text-sm font-semibold leading-6 text-teal-600" translate="landing.pricing.included.head"></h4>
          <div class="h-px flex-auto bg-gray-100"></div>
        </div>
        <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.pricing.included.invoice"></span>
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.pricing.included.monthly"></span>
          </li>

          <li class="flex gap-x-3">
            <!-- Heroicon name: mini/check -->
            <svg class="h-6 w-5 flex-none text-teal-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
            </svg>
            <span translate="landing.pricing.included.monitoring"></span>
          </li>

        </ul>
      </div>
      <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
        <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
          <div class="mx-auto max-w-xs px-8">
            <p class="text-base font-semibold text-gray-600" translate="landing.pricing.details.heading"></p>
            <p class="mt-6 flex items-baseline justify-center gap-x-2">
              <span class="text-5xl font-bold tracking-tight text-gray-900" translate="landing.pricing.details.euro"></span>
              <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600" translate="landing.pricing.details.currency"></span>
            </p>
            <a href="https://sevdata.atlassian.net/servicedesk/customer/portal/3/group/11/create/42" target="_blank" class="mt-10 block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600" translate="landing.pricing.details.button"></a>
            <p class="mt-6 text-xs leading-5 text-gray-600" translate="landing.pricing.details.info"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="bg-white py-24 sm:py-32" id="partner">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <h2 class="text-lg font-semibold leading-8 tracking-tight text-teal-600" data-aos="fade-up" data-aos-duration="1000" translate="partner.headline"></h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200" translate="partner.text"></p>
      </div>
    </div>

    <div class="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-1 mt-10">
      <div class="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
        <img class="self-start" style="width: 300px" src="assets/images/partner/FT_Logo_2022_RZ_RGB.png" alt="">
        <figure class="mt-10 flex flex-auto flex-col justify-between">
          <blockquote class="text-lg leading-8 text-gray-900">
            <p translate="partner.fft.text"></p>
          </blockquote>
        </figure>
      </div>
    </div>
  </div>
</section>


