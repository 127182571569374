import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {IntegrationsPageComponent} from './modules/pages/integrations-page/integrations-page.component';
import {LandingPageComponent} from "./modules/pages/landing-page/landing-page.component";
import {PrivacyPageComponent} from "./modules/pages/privacy-page/privacy-page.component";
import {ImprintPageComponent} from "./modules/pages/imprint-page/imprint-page.component";
import {ProductOverviewPageComponent} from "./modules/pages/product-overview-page/product-overview-page.component";
import {ServicesPageComponent} from "./modules/pages/services-page/services-page.component";
import {PricingPageComponent} from "./modules/pages/pricing-page/pricing-page.component";
import { DetailsComponent } from './modules/pages/product-overview-page/details/details.component';
import { AboutUsComponent } from './modules/pages/about-us/about-us.component';

const routes: Routes = [
  {
    path: 'start',
    component: LandingPageComponent,
    data: { animation: 'landingPage' }
  },
  {
    path: 'privacy',
    component: PrivacyPageComponent,
    data: { animation: 'privacyPage' }
  },
  {
    path: 'imprint',
    component: ImprintPageComponent,
    data: { animation: 'imprintPage' }
  },
  {
    path: 'integrations',
    component: IntegrationsPageComponent,
    data: { animation: 'integrationsPage' }
  },
    /*
  {
    path: 'products',
    data: { animation: 'productPage' },
    children: [
      {
        path: '',
        component: ProductOverviewPageComponent,
        data: { animation: 'productPage' }
      },
      {
        path: 'details',
        component: DetailsComponent,
        data: { animation: 'productPage' }
      },
    ]
  },
     */
  {
    path: 'services',
    component: ServicesPageComponent,
    data: { animation: 'servicesPage' }
  },
  {
    path: 'pricing',
    component: PricingPageComponent
  },
  {
    path: 'about',
    component: AboutUsComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'start'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
