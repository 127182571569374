<div class="bg-gray-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Support center</h2>
            <p class="mt-6 text-lg leading-8 text-gray-300">Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.</p>
        </div>
    </div>
</div>

<div class="bg-white px-6 py-32 lg:px-8" #target>
    <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700">
        <p class="text-base font-semibold leading-7 text-teal-600" translate="integrations.oms.key"></p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.oms.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.oms.text"></p>
        <div class="mt-10 max-w-6xl">
            <table class="w-full text-left max-sm:hidden">
                <colgroup>
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                </colgroup>
                <thead>
                <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
                    <td class="p-0">

                    </td>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Xentral
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            WeClap
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            SAP <br> Business By Design
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Plenty Markets
                        </div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Fulfillment Tools
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="group">
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Aufträge</th>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Auftragsrückmeldung mit Tracking-Daten</th>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lieferschein</th>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lieferscheinrückmeldung mit Tracking-Daten</th>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Bestellung</th>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Wareneingang Bestellung</th>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Retoure</th>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Wareneingang Retoure</th>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Rechnungen</th>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Zahlungseingang Rechnung</th>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lagerzahlen</th>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Artikel</th>
                    <td class="p-2 text-center text-xs italic">Import / Export</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Adressen</th>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700">
        <p class="text-base font-semibold leading-7 text-teal-600" translate="integrations.marketplace.key"></p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.marketplace.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.marketplace.text"></p>
        <div class="mt-10 max-w-6xl">

            <table class="w-full text-left max-sm:hidden">
                <colgroup>
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                </colgroup>
                <thead>
                <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
                    <td class="p-0"></td>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Refurbed</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">HGK</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Hood.de</div>
                    </th>
                </tr>
                </thead>
                <tbody class="group">
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Aufträge</th>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Export (in Entwicklung)</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Auftragsrückmeldung mit Tracking-Daten</th>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">Import (in Entwicklung)</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lieferschein</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lieferscheinrückmeldung mit Tracking-Daten</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Bestellung</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Wareneingang Bestellung</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Retoure</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Wareneingang Retoure</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Rechnungen</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Zahlungseingang Rechnung</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lagerzahlen</th>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">Import (in Entwicklung)</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Artikel</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">Import (in Entwicklung)</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Adressen</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>

<div class="bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700">
        <p class="text-base font-semibold leading-7 text-teal-600" translate="integrations.shops.key"></p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.shops.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.shops.text"></p>
        <div class="mt-10 max-w-6xl">

            <table class="w-full text-left max-sm:hidden">
                <colgroup>
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                    <col class="w-2/12">
                </colgroup>
                <thead>
                <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
                    <td class="p-0"></td>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Shopify</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Digistore24</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">CopeCart</div>
                    </th>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">Dehner</div>
                    </th>
                </tr>
                </thead>
                <tbody class="group">
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Aufträge</th>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">Export</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Auftragsrückmeldung mit Tracking-Daten</th>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lieferschein</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lieferscheinrückmeldung mit Tracking-Daten</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Bestellung</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Wareneingang Bestellung</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Retoure</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Wareneingang Retoure</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Rechnungen</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Zahlungseingang Rechnung</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Lagerzahlen</th>
                    <td class="p-2 text-center text-xs italic">Import</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Artikel</th>
                    <td class="p-2 text-center text-xs italic">Export</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Adressen</th>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                    <td class="p-2 text-center text-xs italic">&mdash;</td>
                </tr>
                </tbody>
            </table>


        </div>
    </div>
</div>

<div class="bg-white px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-6xl text-base leading-7 text-gray-700">
        <p class="text-base font-semibold leading-7 text-teal-600" translate="integrations.pim.key"></p>
        <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" translate="integrations.pim.headline"></h1>
        <p class="mt-6 text-xl leading-8" translate="integrations.pim.text"></p>
        <div class="mt-10 max-w-6xl">

            <table class="w-full text-left max-sm:hidden">
                <colgroup>
                    <col class="w-2/12">
                    <col class="w-2/12">
                </colgroup>
                <thead>
                <tr class="-mx-4 rounded-lg bg-gray-50 px-4 py-3 text-sm/6 font-semibold text-gray-950">
                    <td class="p-0"></td>
                    <th scope="col" class="p-0">
                        <div class="text-sm/5 font-semibold text-center">
                            Plytix
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="group">
                <tr class="border-b border-gray-100 last:border-none">
                    <th scope="row" class="px-0 py-2 text-sm/6 font-normal text-gray-600">Artikel</th>
                    <td class="p-2 text-center text-xs italic">Export</td>
                </tr>
                </tbody>
            </table>



        </div>
    </div>
</div>