<div class="flex">
  <div class="max-w-3xl mx-auto mt-8 mb-10">
    <h1 class="text-2xl font-bold">Impressum</h1>

    <h2 class="font-bold mb-2 mt-3">Angaben gemäß § 5 TMG</h2>
    <p>SevData GmbH<br>
      Grete-Schickedanz-Str. 9<br>
      55545 Bad Kreuznach</p>

    <p>Handelsregister: HRB 23942<br>
      Registergericht: Bad Kreuznach<br>
      Geschäftsführer:  Daniel Schmidtchen</p>

    <h2 class="font-bold mb-2 mt-3">Vertreten durch:</h2>
      <p>Daniel Schmidtchen</p>

    <h2 class="font-bold mb-2 mt-3">Kontakt</h2>
    <p>E-Mail: daniel@sevdata.de</p>

    <h2 class="font-bold mb-2 mt-3">Umsatzsteuer-ID</h2>
    <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br>
      DE308830656</p>

    <h2 class="font-bold mb-2 mt-3">Redaktionell verantwortlich</h2>
    <p>Daniel Schmidtchen<br>
      Grete-Schickedanz-Str. 9<br>
      55545 Bad Kreuznach</p>

    <h2 class="font-bold mb-2 mt-3">EU-Streitschlichtung</h2>
    <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
  </div>


  Photo by <a href="https://unsplash.com/@jipy32?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Jean-Philippe Delberghe</a> on <a href="https://unsplash.com/photos/a-close-up-of-a-white-wall-with-wavy-lines-75xPHEQBmvA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
</div>